var presentClearpay = presentClearpay || {};
var presentAfterpay = presentAfterpay || {};

(function ($) {
  // When the menu changes, trigger skuSelect:
  $(document).on('change', 'select.js-sku-menu', function () {
    var skuId = $(this).val();
    var sku = prodcat.data.getSku(skuId);
    var skuBaseId;

    if (sku) {
      skuBaseId = sku.SKU_BASE_ID;
      if ($(this).hasClass('js-sku-menu--shade')) {
        $(document).trigger('product.updateRoute', ['shade', sku.shadeMenuLabel]);
      } else {
        $(document).trigger('product.updateRoute', ['sku', skuBaseId]);
      }
    }

    // Update Badge
    var $badgeNode = $('.product-full__badge');

    if ($badgeNode.length) {
      var badgeClassPrefix = 'product-full__badge--';
      var class_list = $badgeNode.attr('class').split(/\s+/);

      $(class_list).each(function () {
        className = String(this);
        if (className.indexOf(badgeClassPrefix) != -1) {
          $badgeNode.removeClass(className);
        }
      });
      if (sku.MISC_FLAG_TEXT) {
        $badgeNode.addClass(badgeClassPrefix + sku.MISC_FLAG_TEXT);
      }
    }
  });

  // Likewise, whenever skuSelect is fired on a product, update the menu:
  $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId, context) {
    $('select.js-sku-menu', this).val(skuBaseId);
    $('select.js-sku-menu.selectBox-attached', this).selectBox('value', skuBaseId);
    var $product = $(this);
    var $sppPage = $('.spp__container', context);

    if ($sppPage) {
      $('select.js-sku-menu.selectBox-attached', this)
        .add('.js-spp-product-info-sticky .js-sku-menu')
        .selectBox('value', skuBaseId);
    }
    // Price update logic
    var sku = prodcat.data.getSku(skuBaseId);
    var product = prodcat.data.getProduct(sku.PRODUCT_ID);
    var $autoReplenishment = $('.js-product-replenishment-select', $product);

    if (sku) {
      // If the product is shaded, update all images on SKU change
      if (product.shaded === 1) {
        try {
          if (sku.LARGE_IMAGE[0]) {
            $product.find('.js-product-image-main-pc').attr('src', sku.LARGE_IMAGE[0]);
          }
          if (sku.IMAGE_L[0]) {
            $product.find('.js-product-image-main-mobile').attr('src', sku.IMAGE_L[0]);
          }
          for (var i = 0; i < sku.LARGE_ALT_IMAGES.length; i++) {
            $product.find('.js-product-image-alt-pc').eq(i).attr('src', sku.LARGE_ALT_IMAGES[i]);
          }
          for (var i = 0; i < sku.IMAGE_L_ALT_IMAGES.length; i++) {
            $product.find('.js-product-image-alt-mobile').eq(i).attr('src', sku.IMAGE_L_ALT_IMAGES[i]);
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        // For non shaded products (i.e. sized) only the first image changes per SKU
        // The alt images are pulled from the default SKU
        if (sku.LARGE_IMAGE[0]) {
          $product.find('.js-product-image-main-pc').attr('src', sku.LARGE_IMAGE[0]);
          if ($sppPage) {
            $('.js-spp-product-info-sticky').find('.js-product-image').attr('src', sku.LARGE_IMAGE[0]);
          }
        }
        if (sku.IMAGE_L[0]) {
          $product.find('.js-product-image-main-mobile').attr('src', sku.IMAGE_L[0]);
        }
      }
      // Need to wrap sku inside of defaultSku because thats where the template reads
      var content = site.template.get({
        name: 'product_sku_price',
        data: { defaultSku: sku }
      });

      $('.product-sku-price', this).html($(content).html());
      if (Drupal.settings.common.clearpay_unit_price) {
        var amountClearpay = $('.js-sku-price').html().replace(/[^\d]/g, '');

        if (amountClearpay !== null && typeof presentClearpay.clearpayConfig === 'function') {
          presentClearpay.clearpayConfig(amountClearpay);
        }
      }
      if (Drupal.settings.afterpay_price) {
        var amount = $('.js-sku-price').html().replace(/[^\d]/g, '');

        if (amount !== null && typeof presentAfterpay.afterpayConfig === 'function') {
          presentAfterpay.afterpayConfig(amount);
        }
      }
    }
    $autoReplenishment.hide();
    if (sku.REFILLABLE === 1) {
      $autoReplenishment.show();
    }
  });
})(jQuery);
